<template>
  <section class="container">
    <div v-if="challenges && challenges.length > 0">
      <ChallengeCardCoordinator
        v-for="c in challenges"
        :key="c.id"
        class="mb-2"
        :challenge="c"
        @updated="onUpdateChallenge"
      />
    </div>
    <div v-else>
      <EmptyChallenges />
    </div>
  </section>
</template>

<script>
import { COMPUTED_CHALLENGES_LIST } from "@/models/challenges/operations.gql";
import ChallengeCardCoordinator from "@/components/challengeCard/ChallengeCardCoordinator.vue";
import EmptyChallenges from "@/components/dashboard/EmptyChallenges.vue";
import { isNotDeleted } from "@/models/forums/predicates.js";

export default {
  name: "ChallengesTab",
  components: {
    EmptyChallenges,
    ChallengeCardCoordinator
  },
  data() {
    return {
      challenges: []
    };
  },
  apollo: {
    challenges: {
      query: COMPUTED_CHALLENGES_LIST,
      variables() {
        return {
          expandPrompts: true,
          where: {
            ...isNotDeleted(),
            _or: [
              { current_user_accepted_at: { _is_null: false } },
              { author_id: { _eq: this.$auth.user.id } }
            ]
          }
        };
      },
      update({ challenges }) {
        return challenges;
      }
    }
  },
  methods: {
    onCreateChallenge(challenge) {
      this.$log.debug("Challenge Created", challenge);
      this.isShowingChallengeForm = false;
    },
    onUpdateChallenge(challenge) {
      this.$log.info("Challenge Updated", challenge);
    }
  }
};
</script>
