<template>
  <EmptyState class="boxed elevation-1 mb-3 p-3 my-2">
    <h5>You have not signed up for any challenges.</h5>
    <p class="p-2">Search for a challenge and spark your creativity!</p>
    <div class="is-flex mt-1 is-justify-content-space-between is-align-items-center">
      <b-button
        tag="router-link"
        :to="{ name: 'challenges' }"
        type="button button is-info"
        label="View All Challenges"
      />
    </div>
  </EmptyState>
</template>

<script>
import EmptyState from "@/components/common/EmptyState.vue";

export default {
  name: "EmptyChallenges",
  components: {
    EmptyState
  }
};
</script>
